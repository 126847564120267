import styled, { keyframes, css } from "styled-components";

import { ButtonProps } from "./index";

const animationClose = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(480deg);
  }
`;

export const ButtonWrapper = styled.button<Partial<ButtonProps>>`
    padding: 0;
    cursor: pointer;
    ${({ variant }) =>
        variant === "primary" &&
        css`
            position: relative;
            overflow: hidden;
            font-family: "Helvetica Neue Bold", serif;
            width: 708px;
            height: 104px;

            .button-bottom-icons {
                svg {
                    width: 90%;
                    top: 150%;
                    position: absolute;
                    transition: all 1s;
                }

                .button-right-bottom-icon {
                    right: -30%;
                }

                .button-left-bottom-icon {
                    left: -30%;
                }
            }

            .button-top-icons {
                svg {
                    height: 90%;
                    width: 12%;
                    top: -110%;
                    position: absolute;
                    transition: all 1s;
                }

                .button-left-top-icon {
                    left: 0;
                }

                .button-right-top-icon {
                    right: 0;
                }
            }

            &:hover {
                .button-bottom-icons svg,
                .button-top-icons svg {
                    height: 110%;
                }

                .button-left-top-icon {
                    left: 5%;
                    top: -35%;
                }

                .button-right-top-icon {
                    right: 4%;
                    top: -35%;
                }

                .button-right-bottom-icon {
                    top: 60%;
                    right: -21%;
                }

                .button-left-bottom-icon {
                    top: 34%;
                    left: -32%;
                }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
                width: 648px;
                height: 96px;
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                width: 548px;
                height: 81px;
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                width: 497px;
                height: 74px;
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                width: 343px;
                height: 72px;

                .button-top-icons svg {
                    width: 20%;
                }
            }

            &:before,
            &:after {
                content: "";
                height: 2px;
                background-color: ${({ theme }) => theme.palette.primary.main};
                width: calc(100% - 10px);
                position: absolute;
                top: 0;
                z-index: 9;
            }

            &:after {
                bottom: 0;
                top: unset;
            }

            .button-right-border,
            .button-left-border {
                height: 100%;
                width: 30px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 9;
                overflow: hidden;
            }

            .button-left-border {
                right: unset;
                left: 0;
            }
        `};

    ${({ variant }) =>
        variant === "secondary" &&
        css`
            border: none;
            position: relative;

            img {
                animation: ${animationClose} 6.5s linear 0s infinite normal none running;
                width: 280px;
            }

            span {
                position: absolute;
                display: flex;
                transition: all 1s ease-in-out;
                font-size: ${({ theme }) => theme.spacing(16.25)};
                color: ${({ theme }) => theme.palette.primary.main};
                width: 100%;
                top: 105px;
                align-items: center;
                justify-content: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-39deg);
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
                img {
                    width: 246px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(11.75)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                img {
                    width: 210px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(10.75)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                img {
                    width: 154px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(7)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                img {
                    width: 120px;
                }

                span {
                    font-size: ${({ theme }) => theme.spacing(4)};
                }
            }

            &:hover {
                span {
                    transform: rotate(0deg);
                    top: ${({ theme }) => theme.spacing(21.25)};
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(0deg);
                }
            }
        `};

    ${({ variant }) =>
        variant === "link" &&
        css`
            background-color: transparent;
            overflow: hidden;
            border: none;

            .link {
                font-size: ${({ theme }) => theme.spacing(10)};
                position: relative;
                white-space: nowrap;
            }

            .link--iocaste {
                font-family: lust-fine, sans-serif;
                overflow: hidden;
                padding: ${({ theme }) => theme.spacing(0.5, 0)};
            }

            .link__graphic {
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                fill: none;
                stroke: ${({ theme }) => theme.palette.primary.main};
                stroke-width: 1px;
            }

            .link__graphic--slide {
                top: -3px;
                stroke-width: 2px;
                transition: transform 0.7s;
                transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
            }

            .link__graphic--slide {
                top: 1px;
                stroke-width: 2px;
                transition: transform 0.7s;
                transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
            }

            svg {
                min-width: 300px;
            }

            label {
                color: ${({ theme }) => theme.palette.primary.main};
            }

            span {
                text-decoration: none;
                display: block;
            }

            &:hover {
                .link__graphic--slide {
                    transform: translate3d(-66.6%, 0, 0);
                }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(8)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(6)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("sm")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(5)};
                }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
                .link {
                    font-size: ${({ theme }) => theme.spacing(4.5)};
                }
            }
        `};

    ${({ variant }) =>
        variant === "text" &&
        css`
            border: none;
            background-color: transparent;

            a {
              text-decoration: none;
            }

            .content__item {
              width: 100%;
              height: 100%;
              margin: ${({ theme }) => theme.spacing(0)};
              padding: ${({ theme }) => theme.spacing(0)};
              counter-increment: itemcounter;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
            }

            .content__item::before {
              color: var(--color-number);
              position: absolute;
              top: 0;
              left: 0;
              content: counters(itemcounter, ".", decimal-leading-zero);
            }

            .link {
              font-size: ${({ theme }) => theme.spacing(4.5)};
              position: relative;
              white-space: nowrap;
              color: var(--color-text);
            }

            .link::before,
            .link::after {
              position: absolute;
              width: 100%;
              height: 1px;
              background: currentColor;
              top: 100%;
              left: 0;
              pointer-events: none;
            }

            .link::before {
              content: '';
            }

            .link--herse {
              font-size: ${({ theme }) => theme.spacing(10)};
              font-weight: bold;
            }

            .link--herse::before {
              display: none;
            }

            .link__graphic {
              position: absolute;
              top: 0;
              left: 0;
              pointer-events: none;
              fill: none;
              stroke: ${({ theme }) => theme.palette.primary.main};
              stroke-width: 1px;
            }

            .link__graphic--stroke path {
              stroke-dasharray: 1;
              stroke-dashoffset: 1;
            }

            .link__graphic--arc {
              top: 73%;
              left: -12%;
            }

            .link__graphic--arc path {
              transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
            }

            span {
              color: ${({ theme }) => theme.palette.primary.main};
            }

            svg {
              width: 65%;
            }

            &:hover {

              .link__graphic--stroke path {
                stroke-dashoffset: 0;
              }

              .link__graphic--arc path {
                transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
                transition-duration: 0.3s;
              }

              svg {
                width: 65%;
              }
            }

            ${({ theme }) => theme.breakpoints.down("xl")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(8)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("lg")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(8)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("md")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(6)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("s")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("sm")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }

            ${({ theme }) => theme.breakpoints.down("xs")} {
              .link--herse {
                font-size: ${({ theme }) => theme.spacing(5)};
                font-weight: bold;
              }
            }
          }
          `};

    ${({}) =>
        css`
            position: relative;
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-only-icon-vertical {
                color: ${({ theme }) => theme.palette.primary.main};
            }

            .button-only-icon-horizontal {
                position: absolute;
                transform: rotate(90deg);
                color: ${({ theme }) => theme.palette.primary.main};
                display: block;
                transition: all ease 1s;
            }

            .button-only-icon-horizontal {
                color: ${({ theme }) => theme.palette.primary.main};
                rotate: 90deg;
                transform: rotate(90deg);
            }

            &.button-hover {
                .button-only-icon-horizontal {
                    display: block;
                    fill: ${({ theme }) => theme.palette.primary.main};
                    visibility: hidden;
                    transform: rotate(180deg);
                }
            }
        `};
`;
