import React, { useMemo, useState } from "react";

import { OPEN_POSITIONS } from "common";

import { Box } from "@mui/material";

import { MainButton } from "../Button";
import { Typography } from "../Typography";
import { CareerItem } from "./CareerItem";
import { CareersListWrapper } from "./styles";

export function CareersList() {
    const [openCareer, setOpenCareer] = useState(-1);
    const [showAll, setShowAll] = useState(false);

    const listJobs = useMemo(() => {
        return showAll ? OPEN_POSITIONS : OPEN_POSITIONS.slice(0, 5);
    }, [showAll]);

    return (
        <CareersListWrapper>
            <Box className="careers-header">
                <Typography variant="h3">Open Positions</Typography>
                <Typography variant="p3" className="item-count">
                    ({OPEN_POSITIONS.length})
                </Typography>
            </Box>
            <Box className="career-list">
                <Box className="career-list-item">
                    {listJobs.map((job) => {
                        return (
                            <Box key={job.id}>
                                <Box className="list-item">
                                    <Typography variant="p0" className="job-position">
                                        {job.position}
                                    </Typography>
                                    <Typography variant="p3" className="job-type">
                                        {job.jobType}
                                    </Typography>
                                    <Typography variant="p3" className="job-variant">
                                        {job.jobVariant}
                                    </Typography>
                                    <MainButton
                                        variant="onlyIcon"
                                        onClick={() => {
                                            setOpenCareer((prevState) => {
                                                if (prevState === job.id) {
                                                    return -1;
                                                }
                                                return job.id;
                                            });
                                        }}
                                        isOpen={job.id === openCareer}
                                    />
                                </Box>

                                {job.id === openCareer ? <CareerItem item={job.data} /> : null}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <MainButton
                variant="link"
                text="more"
                onClick={() => {
                    setShowAll((prevState) => !prevState);
                }}
            />
        </CareersListWrapper>
    );
}
