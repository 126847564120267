import React, { useCallback, useEffect, useRef, useState } from "react";

import { Arrow, Typography } from "app-design";

import { SvgIcon, useTheme } from "@mui/material";

import { BackToTopWrapper } from "./styled";

const ARROW_VISIBILITY_BOUND = 200;

export function BackToTop() {
    const {
        palette: { secondary },
    } = useTheme();

    const [isVisible, setIsVisible] = useState(false);

    const appLayout = useRef<HTMLDivElement | null>(null);

    const setShowOrHide = () => {
        return isVisible ? "show" : "hide";
    };

    const onScrollTop = () => {
        if (appLayout && appLayout.current) {
            appLayout.current.scrollTop = 0;
        }
    };

    const listenToScroll = useCallback(() => {
        if (!appLayout.current) return;
        const winScroll = appLayout.current.scrollTop;

        if (winScroll > ARROW_VISIBILITY_BOUND) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, []);

    useEffect(() => {
        appLayout.current = document.querySelector("#app-layout") as HTMLDivElement;

        appLayout.current.addEventListener("scroll", listenToScroll);

        return () => appLayout.current?.removeEventListener("scroll", listenToScroll);
    }, [listenToScroll]);

    return (
        <BackToTopWrapper>
            <div className="back-to-top" onClick={onScrollTop}>
                <Typography variant="p2" color={secondary.main} className="arrow-top-text">
                    Back to top
                </Typography>
                <SvgIcon className={setShowOrHide()} component={Arrow} inheritViewBox />
            </div>
        </BackToTopWrapper>
    );
}
