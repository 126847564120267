import styled from "styled-components";

export const MobileMenuWrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: ${({ theme }) => theme.zIndex.drawer};
    .burger-menu {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        transition: all ease 0.4s;
        color: ${({ theme }) => theme.palette.secondary.main};
        &:hover {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
    .animated-background {
        width: 100%;
        height: 100%;
        transform: translate(100%, -60px);
        position: absolute;
        z-index: 9;
        background-color: ${({ theme }) => theme.palette.background.black};
        animation: right-to-left 1s 1;
    }
    .navbar-item {
        text-transform: capitalize;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .circle {
        position: absolute;
        top: 75px;
        left: 75px;
        -webkit-animation: spin-right 10s linear infinite;
        animation: spin-right 10s linear infinite;
        img {
            position: absolute;
            top: 50px;
            left: -10px;
            opacity: 32%;
            filter: blur(10px);
            border-radius: 50%;
        }
    }
    .circle-0 {
        width: 140px;
        height: 140px;
        top: 0;
        img {
            width: 140px;
        }
    }
    .circle-1 {
        right: 75px;
        left: unset;
        width: 160px;
        height: 160px;
        -webkit-animation: spin-left 10s linear infinite;
        animation: spin-left 10s linear infinite;
        img {
            width: 160px;
        }
    }
    .circle-2 {
        width: 200px;
        height: 200px;
        top: 250px;
        img {
            width: 200px;
        }
    }
    .mobile-menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        height: -webkit-fill-available;
        display: none;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.palette.background.black};
        &.show {
            display: flex;
        }
        .navbar-item {
            margin-bottom: ${({ theme }) => theme.spacing(8)};
        }
        .mobile-menu-footer {
            position: absolute;
            bottom: 24px;
            right: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .email {
                margin-left: auto;
                color: ${({ theme }) => theme.palette.primary.main};
                p {
                    text-transform: lowercase;
                }
            }
            .social-icons {
                margin-left: auto;
                width: fit-content;
                margin-bottom: ${({ theme }) => theme.spacing(5)};
                .social-icon {
                    margin-left: ${({ theme }) => theme.spacing(4)};
                    color: ${({ theme }) => theme.palette.primary.main};
                    &:hover {
                        svg {
                            color: ${({ theme }) => theme.palette.secondary.blue};
                        }
                    }
                }
            }
        }
    }

    @keyframes spin-right {
        100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin-left {
        100% {
            -webkit-transform: rotate(-360deg);
            -moz-transform: rotate(-360deg);
            -ms-transform: rotate(-360deg);
            -o-transform: rotate(-360deg);
            transform: rotate(-360deg);
        }
    }
    @keyframes right-to-left {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }
`;
