import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Icon, AnimatedText } from "app-design";
import { MENU_LIST } from "common";

import CircleL from "../../../../public/img/circle/circleL.png";
import CircleM from "../../../../public/img/circle/circleM.png";
import CircleS from "../../../../public/img/circle/circleS.png";
import { MobileMenuWrapper } from "./styled";

export function MobileMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const circles = [CircleS, CircleM, CircleL];

    const onOpenMenu = () => {
        setOpen(!open);
    };

    return (
        <MobileMenuWrapper>
            <div className="burger-menu">
                <Icon icon={!open ? "BurgerMenu" : "Close"} onClick={onOpenMenu} />
            </div>
            <div className={`mobile-menu${open ? " show" : ""}`}>
                <div className="animated-background" />
                {MENU_LIST.map((page: string, index: number) => {
                    return (
                        <div
                            className="navbar-item"
                            key={`${index}-navbar-item`}
                            onClick={() => {
                                navigate(`/${page.toLowerCase()}`);
                                location.pathname === `/${page.toLowerCase()}` && setOpen(false);
                            }}
                        >
                            <AnimatedText variant="h2" isColoredHover>
                                {page}
                            </AnimatedText>
                            {index < circles.length && (
                                <div className={`circle circle-${index}`}>
                                    <img src={circles[index]} alt={`circle-${index}`} />
                                </div>
                            )}
                        </div>
                    );
                })}
                <div className="mobile-menu-footer">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Inomma" target="_blank" className="social-icon">
                            <Icon icon="Facebook" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/inomma/mycompany/"
                            target="_blank"
                            className="social-icon"
                        >
                            <Icon icon="Linkedin" />
                        </a>
                    </div>
                    <a href="mailto:hello@inomma.com" target="_blank" className="email">
                        <AnimatedText variant="p1">hello@inomma.com</AnimatedText>
                    </a>
                </div>
            </div>
        </MobileMenuWrapper>
    );
}
