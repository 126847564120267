import { createGlobalStyle } from "styled-components";

export const MyGlobalStyles: any = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html {
    overflow-x: hidden;
    user-select: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  html, body {
    height: 100vh;
    height: -webkit-fill-available;
    background-color: #000;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Newake', 'Helvetica Neue', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  button {
    outline: none;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #app-layout {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  #root, #__next {
    isolation: isolate;
    height: 100%;
    height: -webkit-fill-available;
  }

  .inm-split-text {
    display: flex;
  }

  // fonts
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: local('Helvetica Neue Regular'), url('/fonts/Helvetica_Neue_Regular.ttf') format('ttf');
    src: local('Helvetica Neue Regular'), url('/fonts/Helvetica_Neue_Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: local('Helvetica Neue Medium'), url('/fonts/Helvetica_Neue_Medium.ttf') format('ttf');
    src: local('Helvetica Neue Medium'), url('/fonts/Helvetica_Neue_Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue Bold';
    src: local('Helvetica Neue Bold'), url('/fonts/Helvetica_Neue_Bold.ttf') format('ttf');
    src: local('Helvetica Neue Bold'), url('/fonts/Helvetica_Neue_Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Newake';
    src: local('Newake'), url('/fonts/Newake_Regular.ttf') format('ttf');
    src: local('Newake'), url('/fonts/Newake_Regular.woff2') format('woff2');
  }

  .noise {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background-image: url("/img/noise.png");
    z-index: 9;
    animation: noise-animation 0.8s steps(10) infinite;
    pointer-events: none;
    opacity: 50%;
  }

  // animations
  @keyframes noise-animation {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -5%);
    }
    20% {
      transform: translate(-10%, 5%);
    }
    30% {
      transform: translate(5%, -10%);
    }
    40% {
      transform: translate(-5%, 15%);
    }
    50% {
      transform: translate(-10%, 5%);
    }
    60% {
      transform: translate(15%, 0);
    }
    70% {
      transform: translate(0, 10%);
    }
    80% {
      transform: translate(-15%, 0);
    }
    90% {
      transform: translate(10%, 5%);
    }
    100% {
      transform: translate(5%, 0);
    }
  }
`;
