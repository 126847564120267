import React from "react";

import { Typography, TVariants } from "app-design";

import { AnimatedTextWrapper } from "./styled";

interface AnimatedTextProps {
    children: string;
    variant: TVariants;
    isColoredHover?: boolean;
    color?: string;
}

export function AnimatedText({ children, variant, isColoredHover, color }: AnimatedTextProps) {
    const buttonText: string[] = children ? [...children] : [];

    return (
        <AnimatedTextWrapper variant={variant} isColoredHover={isColoredHover}>
            <div className="default-item">
                {buttonText.map((char, index) => {
                    return (
                        <Typography
                            variant={variant}
                            key={`${char}__${index}`}
                            className={char === " " ? "empty-space" : ""}
                            style={{ "--index": index } as React.CSSProperties}
                            color={color}
                        >
                            {`${char}`}
                        </Typography>
                    );
                })}
            </div>
            <div className="clone-item">
                {buttonText.map((char, index) => (
                    <Typography
                        variant={variant}
                        key={`${index}__${char}`}
                        className={char === " " ? "empty-space" : ""}
                        style={{ "--index": index } as React.CSSProperties}
                        color={color}
                    >
                        {char}
                    </Typography>
                ))}
            </div>
        </AnimatedTextWrapper>
    );
}
