import { TVariants } from "app-design";
import styled from "styled-components";

interface AnimatedTextWrapperProps {
    variant: TVariants;
    isColoredHover?: boolean;
}

export const AnimatedTextWrapper = styled("div")<Partial<AnimatedTextWrapperProps>>`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &:hover {
        .clone-item .${({ variant }) => variant} {
            transform: translate(0) rotateX(0);
            color: ${({ theme, isColoredHover }) => (isColoredHover ? theme.palette.primary.main : "inherit")};
        }

        .default-item .${({ variant }) => variant} {
            transform: translateY(-100%) rotateX(-90deg);
            color: ${({ theme, isColoredHover }) => (isColoredHover ? theme.palette.primary.main : "inherit")};
        }
    }

    .empty-space {
        margin-right: ${({ theme }) => theme.spacing(2)};
    }

    .default-item {
        display: flex;
    }

    .clone-item {
        position: absolute;
        display: flex;
    }

    .default-item .${({ variant }) => variant} {
        user-select: none;
        will-change: transform;
        transform-style: preserve-3d;
        transition: 0.5s ease;
        transition-delay: calc(0.05s * var(--index));
        transform-origin: bottom;
        display: inline-block;
    }

    .clone-item .${({ variant }) => variant} {
        user-select: none;
        will-change: transform;
        transform-style: preserve-3d;
        transition: 0.5s ease;
        transition-delay: calc(0.05s * var(--index));
        transform-origin: top;
        display: inline-block;
        transform: translateY(100%) rotateX(-90deg);
        font-style: italic;
    }
`;
