export const MENU_LIST = ["About", "Projects", "Contact"];

export const SLIDES = [
    {
        firstTitle: "Our",
        secondTitle: "vision",
        description: ["Be a reliable partner and drive client success through scaling globally."],
    },
    {
        firstTitle: "Our",
        secondTitle: "mission",
        description: [
            "Create a streamlined and highly performant destination for  spending, while supporting expansions of gameplay on the web and mobile.",
        ],
    },
    {
        firstTitle: "Our",
        secondTitle: "values",
        boldTexts: ["Ownership", "Openness", "Quality", "Outcome"],
        description: [
            " - treat the project as if it’s yours.",
            " - evaluating not only change but also the result/impact of that change.",
            " - strive to grow, help others to grow.",
            " - move fast and win together.",
        ],
    },
];
