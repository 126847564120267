import styled from "styled-components";

const CURSOR_SIZE = "12px";

export const PointerStyles = styled.div`
    position: relative;
    & .inm-pointer {
        position: absolute;
        width: ${CURSOR_SIZE};
        height: ${CURSOR_SIZE};
        margin: -6px 0 0 -6px;
        background-color: ${({ theme }) => theme.palette.secondary.blue};
        border-radius: 50%;
        will-change: transform;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        z-index: 1000000000;
    }
`;
